import * as React from 'react';
import { PageProps } from 'gatsby';

const RESPONSE_EVENT_NAME = 'familyfriendresponsesearch';

export const listenResponse = (onEvent: (payload: string) => void): (() => void) => {
  const receiveMessage = (e: any) => {
    if (e.data && e.data.type === RESPONSE_EVENT_NAME && typeof e.data.payload === 'string') {
      onEvent(e.data.payload);
    }
  };

  window.addEventListener('message', receiveMessage, false);
  return () => window.removeEventListener('message', receiveMessage, false);
};

// Это белая страница, которая слушает сообщение от родителя и если это REQUEST_EVENT_NAME, то закидывает туда search.location
// там валяется что-то вроде orderId=xxx, это уйдет на бек и он скажет прошел платеж или нет

const PaymentReturnPage = ({ location }: PageProps) => {
  React.useEffect(() => {
    const t = setInterval(() => {
      if (typeof window !== 'undefined') {
        const msg = { type: RESPONSE_EVENT_NAME, payload: location.search };

        // @ts-ignore
        if (window.ReactNativeWebView) {
          // @ts-ignore
          window.ReactNativeWebView.postMessage(JSON.stringify(msg));
        } else if (window.parent) {
          window.parent.postMessage(msg, '*');
          // @ts-ignore
        }
      }
    }, 100);

    return () => clearTimeout(t);
  }, [location.search]);

  return <div>Загрузка...</div>;
};

export default PaymentReturnPage;
